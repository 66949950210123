import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header'; // Import the shared Header component
import farzanImage from '../assets/Farzan.png'; // Assuming you have this image

const Contact = () => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const mailtoLink = `mailto:info@arshianetwork.com?subject=Contact%20Form%20Submission%20from%20${form.name}&body=Name:%20${form.name}%0AEmail:%20${form.email}%0AMessage:%20${form.message}`;
    
    window.location.href = mailtoLink;
  };

  return (
    <div>
      <Header /> {/* Including the Header component */}

      {/* Contact Section */}
      <div className="container mx-auto py-12 px-4 bg-gradient-to-r from-blue-700 to-blue-500 text-white">
        <h1 className="text-3xl md:text-4xl font-bold text-center mb-8">DROP US A LINE</h1>
        <div className="flex flex-wrap justify-center items-center">
          {/* Form */}
          <div className="w-full md:w-1/2 mb-8">
            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-6 pt-6 pb-8 mb-4">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={form.name}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={form.email}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                  Message
                </label>
                <textarea
                  name="message"
                  id="message"
                  value={form.message}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  rows="4"
                  required
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>

          {/* Contact Information for Arshia and Farzan */}
          <div className="w-full md:w-1/2 md:pl-10">
            <div className="flex flex-col md:flex-row items-center md:items-start">
              {/* Farzan's Info */}
              <div className="flex flex-col items-center md:items-start">
                <img src={farzanImage} alt="Farzan Feizmohammady" className="w-24 h-24 md:w-32 md:h-32 rounded-full mb-4" />
                <h2 className="text-xl md:text-2xl font-bold mb-4">Contact Information</h2>
                <p className="mb-2">
                  <strong>Email:</strong> <br />info@arshianetwork.com
                </p>
                <p className="mb-2">
                  <strong>Phone:</strong> <br />+1 (647) 870-4552
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Section */}
      <footer className="bg-gradient-to-r from-blue-700 to-blue-500 text-white py-16 w-full">
        <div className="container mx-auto px-6 md:px-12">
          <div className="flex flex-wrap justify-between text-center md:text-left">
            {/* Logo and Contact Info */}
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h2 className="text-2xl md:text-3xl font-bold mb-2 drop-shadow-md">Arshia Network</h2>
              <p className="text-lg md:text-xl">Email: info@arshianetwork.com</p>
              <p className="text-lg md:text-xl">Phone: +1 (647) 870-4552</p>
            </div>

            {/* Navigation Links */}
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-xl md:text-2xl font-semibold mb-4">Navigation</h3>
              <ul className="text-lg md:text-xl">
                <li><Link to="/" className="hover:underline">Home</Link></li>
                <li><Link to="/about" className="hover:underline">About</Link></li>
                <li><Link to="/services" className="hover:underline">Services</Link></li>
                <li><Link to="/portfolio" className="hover:underline">Portfolio</Link></li>
                <li><Link to="/contact" className="hover:underline">Contact</Link></li>
                <li><Link to="/account" className="hover:underline">Account</Link></li>
              </ul>
            </div>

            {/* Services Links */}
            <div className="w-full md:w-1/3">
              <h3 className="text-xl md:text-2xl font-semibold mb-4">Services</h3>
              <ul className="text-lg md:text-xl">
                <li><Link to="/services" className="hover:underline">Web Design</Link></li>
                <li><Link to="/services" className="hover:underline">Website Maintenance</Link></li>
                <li><Link to="/services" className="hover:underline">SEO Services</Link></li>
                <li><Link to="/services" className="hover:underline">Content Creation</Link></li>
                <li><Link to="/services" className="hover:underline">Logo Design</Link></li>
                <li><Link to="/services" className="hover:underline">Network Security</Link></li>
                <li><Link to="/services" className="hover:underline">Cloud Integration</Link></li>
                <li><Link to="/services" className="hover:underline">E-Commerce Development</Link></li>
              </ul>
            </div>
          </div>
          <div className="mt-12 text-center">
            <p className="text-lg md:text-xl">© 2024 Arshia Network. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Contact;
