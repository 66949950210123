import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Header from '../components/Header';
import mobileFirstIcon from '../assets/mobile-first-icon.svg';
import responsiveIcon from '../assets/responsive-icon.svg';
import optimizationIcon from '../assets/optimization-icon.svg';
import farzanImage from '../assets/Farzan.png';
import consultingImage from '../assets/Consulting.webp';
import maintenanceImage from '../assets/maintenance.jpeg';
import technicalSupportImage from '../assets/Technical-Support.jpg';
import webdesignImage from '../assets/webdesign.png';
import routersImage from '../assets/routers.jpeg';
import wifiRoutersImage from '../assets/wifirouters-2048px-3591-2x1-1-1.jpg.webp';

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1.5 } },
};

const Home = () => {
  return (
    <motion.div initial="hidden" animate="visible" variants={fadeIn}>
      {/* Header */}
      <Header />

     {/* Small Business Web Design Section (Hero) */}
<motion.section
  id="hero"
  className="relative text-white py-20 md:py-40"
  style={{ backgroundImage: `url(${require('../assets/hero.jpg')})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
  initial={{ opacity: 0, y: -50 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ duration: 1 }}
>
  {/* Overlay for better text visibility */}
  <div className="absolute inset-0 bg-black opacity-60"></div>

  <div className="relative container mx-auto text-center px-6 md:px-12">
    <h1 className="text-5xl md:text-7xl font-extrabold mb-8 md:mb-14 tracking-wide leading-tight text-shadow-md">
      Small Business IT Solutions + Custom Web Design
    </h1>
    <p className="text-xl md:text-3xl mb-10 md:mb-16 max-w-4xl mx-auto font-light leading-relaxed text-shadow-lg">
      We provide a range of IT services to keep your business running smoothly, along with beautifully hand-coded websites tailored to your brand. No page builders or templates—just superior results crafted for your needs.
    </p>
    <Link
      to="/contact"
      className="relative bg-white text-blue-900 hover:bg-gray-300 font-bold py-4 px-10 rounded transition duration-300 shadow-lg hover:shadow-xl transform hover:scale-105"
    >
      CONTACT US TODAY
    </Link>
  </div>
</motion.section>


      {/* IT Services Section */}
      <motion.section id="it-services" className="py-16 md:py-24 bg-gray-100 text-gray-800" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        <div className="container mx-auto text-center px-6 md:px-12">
          <h2 className="text-4xl md:text-5xl font-bold mb-12 tracking-tight">Our IT Services</h2>
          <p className="text-lg md:text-xl max-w-3xl mx-auto mb-12 text-gray-600 font-light">
            We provide a comprehensive set of IT services to address all the technological needs of your small business—from troubleshooting to network setup and ongoing maintenance.
          </p>
          <div className="flex flex-wrap justify-center gap-16">
            {/* Consulting Services Section */}
            <motion.div className="flex flex-col md:flex-row items-center max-w-3xl bg-white rounded-lg shadow-xl p-8 md:p-12 hover:shadow-2xl transition-shadow duration-300" whileHover={{ scale: 1.05 }}>
              <div className="md:w-1/2 mb-6 md:mb-0">
                <img src={consultingImage} alt="Consulting Services" className="w-full rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300" />
              </div>
              <div className="md:w-1/2 md:pl-12">
                <h3 className="text-3xl font-semibold mb-6">Consulting Services</h3>
                <p className="text-lg text-gray-600 mb-6 font-light">
                  Our team offers expert consulting services to guide you through the process of integrating IT solutions, building a new website, or revamping your existing one to ensure smooth operation. We provide strategic, tailor-made solutions for your business needs.
                </p>
                <Link to="/services" className="bg-blue-600 text-white hover:bg-blue-700 font-bold py-3 px-6 rounded transition duration-300 shadow-md hover:shadow-lg transform hover:scale-105">
                  LEARN MORE
                </Link>
              </div>
            </motion.div>

            {/* Technical Support Section */}
            <motion.div className="flex flex-col md:flex-row items-center max-w-3xl bg-white rounded-lg shadow-xl p-8 md:p-12 hover:shadow-2xl transition-shadow duration-300" whileHover={{ scale: 1.05 }}>
              <div className="md:w-1/2 mb-6 md:mb-0">
                <img src={technicalSupportImage} alt="Technical Support" className="w-full rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300" />
              </div>
              <div className="md:w-1/2 md:pl-12">
                <h3 className="text-3xl font-semibold mb-6">Technical Support</h3>
                <p className="text-lg text-gray-600 mb-6 font-light">
                  We provide reliable technical support to solve any IT issues you might encounter. Our team is ready to help with troubleshooting, software installations, network setup, and more, ensuring your business keeps running smoothly.
                </p>
                <Link to="/services" className="bg-blue-600 text-white hover:bg-blue-700 font-bold py-3 px-6 rounded transition duration-300 shadow-md hover:shadow-lg transform hover:scale-105">
                  FIND OUT MORE
                </Link>
              </div>
            </motion.div>

            {/* Network Setup Section */}
            <motion.div className="flex flex-col md:flex-row items-center max-w-3xl bg-white rounded-lg shadow-xl p-8 md:p-12 hover:shadow-2xl transition-shadow duration-300" whileHover={{ scale: 1.05 }}>
              <div className="md:w-1/2 mb-6 md:mb-0">
                <img src={routersImage} alt="Network Setup" className="w-full rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300" />
              </div>
              <div className="md:w-1/2 md:pl-12">
                <h3 className="text-3xl font-semibold mb-6">Network Setup</h3>
                <p className="text-lg text-gray-600 mb-6 font-light">
                  Our network setup services ensure your office has reliable and secure connectivity. From router configurations to entire network installations, we help set up your business for success.
                </p>
                <Link to="/services" className="bg-blue-600 text-white hover:bg-blue-700 font-bold py-3 px-6 rounded transition duration-300 shadow-md hover:shadow-lg transform hover:scale-105">
                  LEARN MORE
                </Link>
              </div>
            </motion.div>

            {/* WiFi Optimization Section */}
            <motion.div className="flex flex-col md:flex-row items-center max-w-3xl bg-white rounded-lg shadow-xl p-8 md:p-12 hover:shadow-2xl transition-shadow duration-300" whileHover={{ scale: 1.05 }}>
              <div className="md:w-1/2 mb-6 md:mb-0">
                <img src={wifiRoutersImage} alt="WiFi Optimization" className="w-full rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300" />
              </div>
              <div className="md:w-1/2 md:pl-12">
                <h3 className="text-3xl font-semibold mb-6">WiFi Optimization</h3>
                <p className="text-lg text-gray-600 mb-6 font-light">
                  Optimize your business’s WiFi for speed and reliability. We analyze and enhance your network to eliminate dead zones and maximize connection strength, ensuring seamless connectivity for your team and customers.
                </p>
                <Link to="/services" className="bg-blue-600 text-white hover:bg-blue-700 font-bold py-3 px-6 rounded transition duration-300 shadow-md hover:shadow-lg transform hover:scale-105">
                  FIND OUT MORE
                </Link>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.section>

      {/* Website Services Section */}
      <motion.section id="web-services" className="py-16 md:py-24 bg-white text-gray-800" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1.5 }}>
        <div className="container mx-auto text-center px-6 md:px-12">
          <h2 className="text-4xl md:text-5xl font-bold mb-12 tracking-tight">Website Design & Maintenance</h2>
          <p className="text-lg md:text-xl max-w-3xl mx-auto mb-12 text-gray-600 font-light">
            We specialize in delivering state-of-the-art website design and ongoing maintenance services. From visually stunning designs to regular content updates, we ensure your website represents your brand and stays ahead of the competition.
          </p>
          <div className="flex flex-wrap justify-center gap-16">
            {/* Web Design Section */}
            <motion.div className="flex flex-col md:flex-row items-center max-w-3xl bg-gray-100 rounded-lg shadow-xl p-8 md:p-12 hover:shadow-2xl transition-shadow duration-300" whileHover={{ scale: 1.05 }}>
              <div className="md:w-1/2 mb-6 md:mb-0">
                <img src={webdesignImage} alt="Web Design" className="w-full rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300" />
              </div>
              <div className="md:w-1/2 md:pl-12">
                <h3 className="text-3xl font-semibold mb-6">Web Design Services</h3>
                <p className="text-lg text-gray-600 mb-6 font-light">
                  We create visually stunning and highly functional websites that captivate visitors and convert them into customers. Our designs are tailored to your brand and focused on delivering an engaging user experience.
                </p>
                <Link to="/services" className="bg-blue-600 text-white hover:bg-blue-700 font-bold py-3 px-6 rounded transition duration-300 shadow-md hover:shadow-lg transform hover:scale-105">
                  FIND OUT MORE
                </Link>
              </div>
            </motion.div>

            {/* Website Maintenance Section */}
            <motion.div className="flex flex-col md:flex-row items-center max-w-3xl bg-gray-100 rounded-lg shadow-xl p-8 md:p-12 hover:shadow-2xl transition-shadow duration-300" whileHover={{ scale: 1.05 }}>
              <div className="md:w-1/2 mb-6 md:mb-0">
                <img src={maintenanceImage} alt="Website Maintenance" className="w-full rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300" />
              </div>
              <div className="md:w-1/2 md:pl-12">
                <h3 className="text-3xl font-semibold mb-6">Website Maintenance</h3>
                <p className="text-lg text-gray-600 mb-6 font-light">
                  Regular maintenance is crucial for keeping your website up to date and running smoothly. We offer continuous support, including updates, security checks, and content management, to ensure your site always performs at its best.
                </p>
                <Link to="/services" className="bg-blue-600 text-white hover:bg-blue-700 font-bold py-3 px-6 rounded transition duration-300 shadow-md hover:shadow-lg transform hover:scale-105">
                  DISCOVER MORE
                </Link>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.section>

      {/* What We Do Section */}
      <motion.section id="what-we-do" className="py-16 md:py-24 bg-gray-100 text-gray-800" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2 }}>
        <div className="container mx-auto text-center px-6 md:px-12">
          <h2 className="text-4xl md:text-5xl font-bold mb-12 tracking-tight">Our Approach to Web Development</h2>
          <div className="flex flex-wrap justify-center gap-16">
            <motion.div className="flex flex-col items-center max-w-sm bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300" whileHover={{ scale: 1.05 }}>
              <img src={mobileFirstIcon} alt="Mobile-First Design Icon" className="w-20 h-20 mb-6" />
              <h3 className="text-2xl font-semibold mb-4">Mobile-First Design</h3>
              <p className="text-lg text-gray-600 font-light">
                We start building your site for mobile devices first, then add on to make it tablet and desktop ready, ensuring an optimal experience on every screen.
              </p>
            </motion.div>
            <motion.div className="flex flex-col items-center max-w-sm bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300" whileHover={{ scale: 1.05 }}>
              <img src={responsiveIcon} alt="Fully Responsive Icon" className="w-20 h-20 mb-6" />
              <h3 className="text-2xl font-semibold mb-4">Fully Responsive</h3>
              <p className="text-lg text-gray-600 font-light">
                Your website will fit all screen sizes—mobile, tablet, and desktop—ensuring new clients can access your site from anywhere.
              </p>
            </motion.div>
            <motion.div className="flex flex-col items-center max-w-sm bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300" whileHover={{ scale: 1.05 }}>
              <img src={optimizationIcon} alt="Optimization Icon" className="w-20 h-20 mb-6" />
              <h3 className="text-2xl font-semibold mb-4">Optimization</h3>
              <p className="text-lg text-gray-600 font-light">
                We optimize your site for mobile devices to ensure the best performance in search engines and provide your audience with a lightning-fast experience.
              </p>
            </motion.div>
          </div>
        </div>
      </motion.section>

      {/* Who We Are Section */}
      <motion.section id="who-we-are" className="py-16 md:py-24 bg-white text-gray-800" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2.5 }}>
        <div className="container mx-auto px-6 md:px-12">
          <div className="text-center mb-12">
            <h2 className="text-4xl md:text-5xl font-bold mb-4 tracking-tight">Who We Are</h2>
            <p className="text-lg md:text-xl text-gray-600 max-w-2xl mx-auto font-light">
              Arshia Network is a team of passionate professionals dedicated to building clean, professional websites for small businesses. We believe in personalized service, transparent communication, and delivering results that exceed expectations.
            </p>
          </div>
          <div className="flex justify-center items-center gap-16">
            <div className="flex flex-col items-center">
              <img src={farzanImage} alt="Farzan Feizmohammady" className="w-40 h-40 md:w-48 md:h-48 rounded-full mb-4 shadow-md hover:shadow-lg transition-shadow duration-300" />
              <h3 className="text-2xl font-semibold">Farzan Feizmohammady</h3>
              <p className="text-gray-600">CEO</p>
            </div>
          </div>
        </div>
      </motion.section>

     {/* Footer Section */}
     <motion.footer className="bg-gradient-to-r from-blue-700 to-blue-500 text-white py-16 w-full" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2.5 }}>
        <div className="container mx-auto px-6 md:px-12">
          <div className="flex flex-wrap justify-between text-center md:text-left">
            {/* Logo and Contact Info */}
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h2 className="text-2xl md:text-3xl font-bold mb-2 drop-shadow-md">Arshia Network</h2>
              <p className="text-lg md:text-xl">Email: info@arshianetwork.com</p>
              <p className="text-lg md:text-xl">Phone: +1 (647) 870-4552</p>
            </div>

            {/* Navigation Links */}
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-xl md:text-2xl font-semibold mb-4">Navigation</h3>
              <ul className="text-lg md:text-xl">
                <li><Link to="/" className="hover:underline">Home</Link></li>
                <li><Link to="/about" className="hover:underline">About</Link></li>
                <li><Link to="/services" className="hover:underline">Services</Link></li>
                <li><Link to="/portfolio" className="hover:underline">Portfolio</Link></li>
                <li><Link to="/contact" className="hover:underline">Contact</Link></li>
                <li><Link to="/account" className="hover:underline">Account</Link></li>
              </ul>
            </div>

            {/* Services Links */}
            <div className="w-full md:w-1/3">
              <h3 className="text-xl md:text-2xl font-semibold mb-4">Services</h3>
              <ul className="text-lg md:text-xl">
                <li><Link to="/services" className="hover:underline">Web Design</Link></li>
                <li><Link to="/services" className="hover:underline">Website Maintenance</Link></li>
                <li><Link to="/services" className="hover:underline">SEO Services</Link></li>
                <li><Link to="/services" className="hover:underline">Content Creation</Link></li>
                <li><Link to="/services" className="hover:underline">Logo Design</Link></li>
                <li><Link to="/services" className="hover:underline">Network Security</Link></li>
                <li><Link to="/services" className="hover:underline">Cloud Integration</Link></li>
                <li><Link to="/services" className="hover:underline">E-Commerce Development</Link></li>
              </ul>
            </div>
          </div>
          <div className="mt-12 text-center">
            <p className="text-lg md:text-xl">© 2024 Arshia Network. All rights reserved.</p>
          </div>
        </div>
      </motion.footer>
    </motion.div>
  );
};

export default Home;