import React from 'react';
import Header from '../components/Header'; // Assuming your Header component is in the components folder
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import farzanImage from '../assets/Farzan.png'; // Assuming you have this image
import { motion } from 'framer-motion';

const About = () => {
  return (
    <div>
      <Header /> {/* Including the Header component */}
      <div className="container mx-auto py-8 px-4 md:py-16 md:px-8">

        {/* Hero Section */}
        <motion.div 
          className="text-center py-16 md:py-24"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-4">About Us</h2>
          <p className="text-lg md:text-xl max-w-3xl mx-auto mb-6 text-gray-600 font-light">
            Arshia Network is dedicated to providing exceptional IT solutions and custom web design for small businesses. Our mission is to make technology accessible, reliable, and tailored to meet each client's unique needs.
          </p>
        </motion.div>

        {/* Who We Are Section */}
        <motion.div 
          className="flex flex-col items-center mb-16"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          <img src={farzanImage} alt="Farzan Feizmohammady" className="w-24 md:w-40 h-24 md:h-40 rounded-full mb-6 shadow-md hover:shadow-lg transition-shadow duration-300" />
          <h3 className="text-2xl md:text-3xl font-semibold">Farzan Feizmohammady</h3>
          <p className="text-gray-600 mb-4">CEO</p>
          <p className="text-center text-base md:text-lg max-w-2xl">
            My name is Farzan Feizmohammady, and I have over 15 years of experience in the IT industry, holding the prestigious MCSE certification. As the CEO of Arshia Network, a Lenovo Business Pro partner, I lead our team to assist customers with their IT needs, including maintenance, custom solutions, and hardware procurement.
          </p>
        </motion.div>

        {/* Our Values Section */}
        <motion.div
          className="bg-gray-100 p-8 rounded-lg shadow-md mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <h3 className="text-3xl md:text-4xl font-bold text-center mb-8">Our Values</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <h4 className="text-xl font-semibold mb-4">Customer-Centric Approach</h4>
              <p className="text-gray-600 text-base">
                Our clients are at the heart of everything we do. We work closely with you to understand your needs and provide personalized solutions.
              </p>
            </div>
            <div className="text-center">
              <h4 className="text-xl font-semibold mb-4">Professional Integrity</h4>
              <p className="text-gray-600 text-base">
                We hold ourselves to the highest standards of ethics and transparency in every interaction, ensuring trust and reliability.
              </p>
            </div>
            <div className="text-center">
              <h4 className="text-xl font-semibold mb-4">Innovation & Excellence</h4>
              <p className="text-gray-600 text-base">
                We stay at the cutting edge of technology, constantly innovating to provide our clients with the best possible service.
              </p>
            </div>
          </div>
        </motion.div>

        {/* Contact Section */}
        <motion.div 
          className="text-center bg-blue-600 text-white p-8 rounded-lg shadow-md"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <h3 className="text-3xl md:text-4xl font-bold mb-4">Get in Touch</h3>
          <p className="text-lg md:text-xl mb-6 max-w-2xl mx-auto">
            Ready to start a project with us or want to learn more about what we can do for your business? Feel free to reach out.
          </p>
          <Link to="/contact" className="bg-white text-blue-600 font-bold py-3 px-8 rounded-lg transition duration-300 shadow-md hover:bg-gray-200 hover:shadow-lg">
            CONTACT US
          </Link>
        </motion.div>
      </div>

      {/* Footer Section */}
      <motion.footer className="bg-gradient-to-r from-blue-700 to-blue-500 text-white py-16 w-full" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2.5 }}>
        <div className="container mx-auto px-6 md:px-12">
          <div className="flex flex-wrap justify-between text-center md:text-left">
            {/* Logo and Contact Info */}
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h2 className="text-2xl md:text-3xl font-bold mb-2 drop-shadow-md">Arshia Network</h2>
              <p className="text-lg md:text-xl">Email: info@arshianetwork.com</p>
              <p className="text-lg md:text-xl">Phone: +1 (647) 870-4552</p>
            </div>

            {/* Navigation Links */}
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-xl md:text-2xl font-semibold mb-4">Navigation</h3>
              <ul className="text-lg md:text-xl">
                <li><Link to="/" className="hover:underline">Home</Link></li>
                <li><Link to="/about" className="hover:underline">About</Link></li>
                <li><Link to="/services" className="hover:underline">Services</Link></li>
                <li><Link to="/portfolio" className="hover:underline">Portfolio</Link></li>
                <li><Link to="/contact" className="hover:underline">Contact</Link></li>
                <li><Link to="/account" className="hover:underline">Account</Link></li>
              </ul>
            </div>

            {/* Services Links */}
            <div className="w-full md:w-1/3">
              <h3 className="text-xl md:text-2xl font-semibold mb-4">Services</h3>
              <ul className="text-lg md:text-xl">
                <li><Link to="/services" className="hover:underline">Web Design</Link></li>
                <li><Link to="/services" className="hover:underline">Website Maintenance</Link></li>
                <li><Link to="/services" className="hover:underline">SEO Services</Link></li>
                <li><Link to="/services" className="hover:underline">Content Creation</Link></li>
                <li><Link to="/services" className="hover:underline">Logo Design</Link></li>
                <li><Link to="/services" className="hover:underline">Network Security</Link></li>
                <li><Link to="/services" className="hover:underline">Cloud Integration</Link></li>
                <li><Link to="/services" className="hover:underline">E-Commerce Development</Link></li>
              </ul>
            </div>
          </div>
          <div className="mt-12 text-center">
            <p className="text-lg md:text-xl">© 2024 Arshia Network. All rights reserved.</p>
          </div>
        </div>
      </motion.footer>
    </div>
  );
};

export default About;
