import React from 'react';
import Header from '../components/Header'; 
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Services = () => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1.5 }} className="overflow-hidden">
      <Header /> {/* Including the Header component */}
      
      {/* Custom Packages Section */}
      <motion.section id="custom-packages" className="py-20 md:py-28 bg-gray-50 text-gray-800" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1.5 }}>
        <div className="container mx-auto text-center px-6 md:px-12">
          <h2 className="text-4xl md:text-5xl font-bold mb-12 tracking-tight drop-shadow-md">Custom Packages Tailored To You</h2>
          <p className="text-lg md:text-xl max-w-3xl mx-auto mb-16 text-gray-600 font-light">
            We offer customized packages designed to meet your unique business needs—whether it’s web design, IT support, digital marketing, or advanced security solutions. Get everything your business needs in one comprehensive plan, crafted by experts.
          </p>
          <div className="grid gap-16 md:grid-cols-2 lg:grid-cols-3">
            {/* Package 1: Web Design & Maintenance */}
            <motion.div className="bg-white rounded-xl shadow-xl p-8 hover:shadow-2xl transition-shadow duration-300 hover:transform hover:-translate-y-2" whileHover={{ scale: 1.05 }}>
              <div className="bg-blue-100 text-blue-600 py-6 rounded-t-xl mb-6">
                <h3 className="text-2xl font-bold">Web Design & Maintenance</h3>
                <p className="text-lg font-medium mt-2">Custom solutions for your website needs.</p>
              </div>
              <div className="px-6 py-4">
                <ul className="list-disc list-inside text-left text-lg text-gray-600">
                  <li>Fully responsive website design</li>
                  <li>Ongoing website maintenance</li>
                  <li>Custom features based on your business requirements</li>
                  <li>Regular updates and improvements</li>
                  <li>User experience (UX) and user interface (UI) optimization</li>
                </ul>
              </div>
              <div className="px-6 pt-4 pb-6 text-center">
                <Link to="/contact" className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-full transition duration-300 shadow-md hover:shadow-lg transform hover:scale-105">
                  Contact Us for a Quote
                </Link>
              </div>
            </motion.div>

            {/* Package 2: IT Support & Consultation */}
            <motion.div className="bg-white rounded-xl shadow-xl p-8 hover:shadow-2xl transition-shadow duration-300 hover:transform hover:-translate-y-2" whileHover={{ scale: 1.05 }}>
              <div className="bg-yellow-100 text-yellow-600 py-6 rounded-t-xl mb-6">
                <h3 className="text-2xl font-bold">IT Support & Consultation</h3>
                <p className="text-lg font-medium mt-2">Reliable IT services for seamless operations.</p>
              </div>
              <div className="px-6 py-4">
                <ul className="list-disc list-inside text-left text-lg text-gray-600">
                  <li>24/7 technical support</li>
                  <li>System maintenance and upgrades</li>
                  <li>Network management and troubleshooting</li>
                  <li>Expert consultation for IT infrastructure</li>
                  <li>Disaster recovery planning and implementation</li>
                </ul>
              </div>
              <div className="px-6 pt-4 pb-6 text-center">
                <Link to="/contact" className="inline-block bg-yellow-600 hover:bg-yellow-700 text-white font-bold py-3 px-8 rounded-full transition duration-300 shadow-md hover:shadow-lg transform hover:scale-105">
                  Contact Us for a Quote
                </Link>
              </div>
            </motion.div>

            {/* Package 3: SEO & Digital Marketing */}
            <motion.div className="bg-white rounded-xl shadow-xl p-8 hover:shadow-2xl transition-shadow duration-300 hover:transform hover:-translate-y-2" whileHover={{ scale: 1.05 }}>
              <div className="bg-green-100 text-green-600 py-6 rounded-t-xl mb-6">
                <h3 className="text-2xl font-bold">SEO & Digital Marketing</h3>
                <p className="text-lg font-medium mt-2">Boost your visibility and grow your business.</p>
              </div>
              <div className="px-6 py-4">
                <ul className="list-disc list-inside text-left text-lg text-gray-600">
                  <li>On-page and off-page SEO</li>
                  <li>Google Analytics and performance tracking</li>
                  <li>Customized digital marketing strategies</li>
                  <li>Content creation and optimization</li>
                  <li>Social media management and advertising</li>
                </ul>
              </div>
              <div className="px-6 pt-4 pb-6 text-center">
                <Link to="/contact" className="inline-block bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-8 rounded-full transition duration-300 shadow-md hover:shadow-lg transform hover:scale-105">
                  Contact Us for a Quote
                </Link>
              </div>
            </motion.div>

            {/* Package 4: Network Security Solutions */}
            <motion.div className="bg-white rounded-xl shadow-xl p-8 hover:shadow-2xl transition-shadow duration-300 hover:transform hover:-translate-y-2" whileHover={{ scale: 1.05 }}>
              <div className="bg-red-100 text-red-600 py-6 rounded-t-xl mb-6">
                <h3 className="text-2xl font-bold">Network Security Solutions</h3>
                <p className="text-lg font-medium mt-2">Protect your business from cyber threats.</p>
              </div>
              <div className="px-6 py-4">
                <ul className="list-disc list-inside text-left text-lg text-gray-600">
                  <li>Comprehensive network security assessments</li>
                  <li>Firewall setup and configuration</li>
                  <li>Data encryption and secure storage solutions</li>
                  <li>Security training for employees</li>
                  <li>Incident response and management</li>
                </ul>
              </div>
              <div className="px-6 pt-4 pb-6 text-center">
                <Link to="/contact" className="inline-block bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-8 rounded-full transition duration-300 shadow-md hover:shadow-lg transform hover:scale-105">
                  Contact Us for a Quote
                </Link>
              </div>
            </motion.div>

            {/* Package 5: Cloud Services & Integration */}
            <motion.div className="bg-white rounded-xl shadow-xl p-8 hover:shadow-2xl transition-shadow duration-300 hover:transform hover:-translate-y-2" whileHover={{ scale: 1.05 }}>
              <div className="bg-purple-100 text-purple-600 py-6 rounded-t-xl mb-6">
                <h3 className="text-2xl font-bold">Cloud Services & Integration</h3>
                <p className="text-lg font-medium mt-2">Scalable cloud solutions for modern businesses.</p>
              </div>
              <div className="px-6 py-4">
                <ul className="list-disc list-inside text-left text-lg text-gray-600">
                  <li>Cloud migration and deployment</li>
                  <li>Integration with existing infrastructure</li>
                  <li>Data backup and recovery solutions</li>
                  <li>Cloud storage management</li>
                  <li>Support for AWS, Azure, and Google Cloud</li>
                </ul>
              </div>
              <div className="px-6 pt-4 pb-6 text-center">
                <Link to="/contact" className="inline-block bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-8 rounded-full transition duration-300 shadow-md hover:shadow-lg transform hover:scale-105">
                  Contact Us for a Quote
                </Link>
              </div>
            </motion.div>

            {/* Package 6: E-Commerce Development */}
            <motion.div className="bg-white rounded-xl shadow-xl p-8 hover:shadow-2xl transition-shadow duration-300 hover:transform hover:-translate-y-2" whileHover={{ scale: 1.05 }}>
              <div className="bg-teal-100 text-teal-600 py-6 rounded-t-xl mb-6">
                <h3 className="text-2xl font-bold">E-Commerce Development</h3>
                <p className="text-lg font-medium mt-2">Build a robust online store for your business.</p>
              </div>
              <div className="px-6 py-4">
                <ul className="list-disc list-inside text-left text-lg text-gray-600">
                  <li>Custom e-commerce website development</li>
                  <li>Payment gateway integration</li>
                  <li>Product inventory management systems</li>
                  <li>Optimized product pages for conversions</li>
                  <li>Shopping cart and checkout solutions</li>
                </ul>
              </div>
              <div className="px-6 pt-4 pb-6 text-center">
                <Link to="/contact" className="inline-block bg-teal-600 hover:bg-teal-700 text-white font-bold py-3 px-8 rounded-full transition duration-300 shadow-md hover:shadow-lg transform hover:scale-105">
                  Contact Us for a Quote
                </Link>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.section>

      {/* Footer Section */}
      <motion.footer className="bg-gradient-to-r from-blue-700 to-blue-500 text-white py-16 w-full" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2.5 }}>
        <div className="container mx-auto px-6 md:px-12">
          <div className="flex flex-wrap justify-between text-center md:text-left">
            {/* Logo and Contact Info */}
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h2 className="text-2xl md:text-3xl font-bold mb-2 drop-shadow-md">Arshia Network</h2>
              <p className="text-lg md:text-xl">Email: info@arshianetwork.com</p>
              <p className="text-lg md:text-xl">Phone: +1 (647) 870-4552</p>
            </div>

            {/* Navigation Links */}
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-xl md:text-2xl font-semibold mb-4">Navigation</h3>
              <ul className="text-lg md:text-xl">
                <li><Link to="/" className="hover:underline">Home</Link></li>
                <li><Link to="/about" className="hover:underline">About</Link></li>
                <li><Link to="/services" className="hover:underline">Services</Link></li>
                <li><Link to="/portfolio" className="hover:underline">Portfolio</Link></li>
                <li><Link to="/contact" className="hover:underline">Contact</Link></li>
                <li><Link to="/account" className="hover:underline">Account</Link></li>
              </ul>
            </div>

            {/* Services Links */}
            <div className="w-full md:w-1/3">
              <h3 className="text-xl md:text-2xl font-semibold mb-4">Services</h3>
              <ul className="text-lg md:text-xl">
                <li><Link to="/services" className="hover:underline">Web Design</Link></li>
                <li><Link to="/services" className="hover:underline">Website Maintenance</Link></li>
                <li><Link to="/services" className="hover:underline">SEO Services</Link></li>
                <li><Link to="/services" className="hover:underline">Content Creation</Link></li>
                <li><Link to="/services" className="hover:underline">Logo Design</Link></li>
                <li><Link to="/services" className="hover:underline">Network Security</Link></li>
                <li><Link to="/services" className="hover:underline">Cloud Integration</Link></li>
                <li><Link to="/services" className="hover:underline">E-Commerce Development</Link></li>
              </ul>
            </div>
          </div>
          <div className="mt-12 text-center">
            <p className="text-lg md:text-xl">© 2024 Arshia Network. All rights reserved.</p>
          </div>
        </div>
      </motion.footer>
    </motion.div>
  );
};

export default Services;
